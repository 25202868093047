import {
    Row,
    Col,
    Button,
    Card,
    AutoComplete,
    InputNumber,
    Slider,
    Input,
    Modal,
    List,
    message,
    Select,
    DatePicker,
    Typography,
    Pagination,
    Switch,
    Tag,
    Spin,
    Tooltip,
    Tabs,
    Checkbox,
    Radio,
    Badge,
    notification
} from 'antd';

import { Grid, Table } from 'react-bootstrap';

import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import './index.css';
import _ from 'lodash';

import { API_BASE_URL } from '../../variables/Variables';
import { authHeader } from '../../helpers/auth-header';
import {
    ApiOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    BarChartOutlined,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
    InfoCircleTwoTone,
    IssuesCloseOutlined,
    LinkOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    FlagFilled,
    FlagOutlined,
    FlagTwoTone,
    NotificationTwoTone,
    NotificationOutlined,
    ExclamationCircleTwoTone,
    SyncOutlined,
    PauseCircleOutlined
} from '@ant-design/icons';
import HelperInternal from './GADSHelper';
import GADSManagementEdit from './GADSManagementEdit';
import { UserService } from '../../services/user.service';
import copy from 'copy-to-clipboard';

import {DATE_TIME_FORMAT,DATE_FORMAT,DATE_FORMAT_SAVE,DATE_FORMAT_SHORT,CAMPARSION_PERCENTATGE,PACELIMIT,PERCENTAGE_LIMIT,GADS_MICRO_MULTIPLIER} from './GADSconstants';
const { TabPane } = Tabs;
const { Text, Link } = Typography;

const ADDITIONAL_BAR_PARAMETERS = ['cpm', 'ctc', 'total_convs', 'revenue', 'impressions', 'clicks'];

export default props => {
    const [loadingAccounts, setLoadingAccounts] = useState(true);
    const [allAccounts, setAllAccounts] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [allAccountsPage, setAllAccountsPage] = useState(1);
    const [allAccountsItemsPerPage, setAllAccountsItemsPerPage] = useState(25);
    const [loadingATPdata, setLoadingATPdata] = useState(0); // 0-never loaded, 1-loading, 2-loaded
    const [editMode, setEditMode] = useState(false);
    const [editAccount, setEditAccount] = useState(false);
    const [editAccountName, setEditAccountName] = useState('');
    const [removeUnmanagedFlag, setRemoveUnmanagedFlag] = useState(false);
    const [sortingField, setSortingField] = useState('Name');
    const [sortingDirection, setSortingDirection] = useState('asc');
    const [accountFilter, setAccountFilter] = useState('');
    const [allUsers, setAllUsers] = useState({});

    const [percentageOff, setPercentageOff] = useState(0);
    // const [hasMessages, setHasMessages] = useState(false);
    const [messageTypeFilter, setMessageTypeFilter] = useState('all');
    const [managerAccountFilter, setManagerAccountFilter] = useState('all_managements');
    const [limitedData, setLimitedData] = useState(false);
    const [todayPlan, setTodayPlan] = useState({});
    const gadsManagementEdit = useRef(null);
    const startDate = moment().startOf('months')
    const endDate = moment().endOf('months')
    const yesterdayDate = moment().add(-1,'days')
    const daysGone = yesterdayDate.diff(yesterdayDate,'days')
    const daysInMonth = yesterdayDate.daysInMonth()

    useEffect(() => {
        getAllAccounts();
        getAllUsers();
    },[])

    useEffect(() => {
        if (loadingATPdata!==0 || !allAccounts.length) return
        getATPData();
    },[allAccounts])

    const getAllUsers = () => {
        UserService.getAll().then(users => {
            const allUsers = {}
            if (users[1]){
                users[1].map(user=>{allUsers[user.id]=user})
                setAllUsers(allUsers);
            }
        })
    }

    const getATPData = () => {
        setLoadingATPdata(1)
        return axios
        .get(
            `${API_BASE_URL}gadsmanagement/atp`,
            {
                headers: authHeader()
            }
        )
        .then(response => {
            return HelperInternal.processResponse(response, ((!!response.data.data && typeof(response.data.data) === 'string' )?response.data.data:'Error getting ATP data'));
        })
        .then(allATP => {
            const allAccountsTemp = [...allAccounts]
            allAccountsTemp.forEach(account => {
                account.ATPstatus='no'
                if (!account.master_id || !account.master_id.length){return}
                account.master_id.map(master_id=>{
                    if (master_id in allATP){
                        account.ATPstatus=(allATP[master_id].toUpperCase()==='PAUSED' && account.ATPstatus!=='active')?'paused':'active';
                    }
                })
            })
            setAllAccounts(allAccountsTemp)
        })
        .catch(error => {message.error(error.message)})
        .finally(() => {setLoadingATPdata(2)});
    }

    const getAllAccounts = () => {
        if (!startDate || !endDate) {
            return;
        }
        setLoadingAccounts( true );
        const user = UserService.getInfoFromStorage()
        return axios
            .get(
                `${API_BASE_URL}gadsmanagement/getallaccounts/${user.user.id}`,
                {
                    headers: authHeader()
                }
            )
            .then(response => {
                return HelperInternal.processResponse(response, 'Error getting Accounts ');
            })
            .then(response => {
                const allAccountsTemp = Object.values(response).map(account => {
                    const master_id = []
                    if(_.isEmpty(account.connectors)) return account
                    account.connectors.forEach(connector => {
                        if(!master_id.includes(connector.partner_id)){
                            master_id.push(connector.partner_id)
                        }
                    })
                    return {...account,...{master_id}}
                });
                setAllAccounts(allAccountsTemp)
                setFilteredAccounts(allAccountsTemp)
                // getBarData();
            })
            .catch(error => {
                message.error(` ${error} .`);
                console.error(error);
            })
            .finally(() => {
                setLoadingAccounts( false );
            });
    }

    const filteredByMessages = (notifications, filterType) => {            
            if (filterType==="all" || !notifications) {return notifications}
            if (filterType==="any" && notifications.length > 0){return notifications}
        return notifications.filter(notification => {
            return notification.type.indexOf(filterType)>-1
        })
    }

    const handleFilterText = (text = '', removeUnmanaged = false, messageTypeFilter='all', percentageOff=0, managerAccountFilter='all_managements') => {
        const accountFilter = text;
        const removeUnmanagedFlag = removeUnmanaged;
        const searchText = accountFilter.toUpperCase()
        setMessageTypeFilter(messageTypeFilter)
        const managementFilter = value => {
            if (managerAccountFilter==='all_managements') return true
            if (managerAccountFilter==='all_except' && value.indexOf('3186414929')===-1 && value.indexOf('7406500815')===-1 && value.indexOf('7116929211')===-1) return true
            if (managerAccountFilter==='3186414929' && value.indexOf('3186414929')>-1) return true
            if (managerAccountFilter==='7406500815' && value.indexOf('7406500815')>-1) return true
            if (managerAccountFilter==='7116929211' && value.indexOf('7116929211')>-1) return true
            return false
        }
        const filteredAccounts = allAccounts.filter(account => {
            const filteredNotifications = filteredByMessages(account.notification,messageTypeFilter)
            return (
                ((account.name && account.name.toUpperCase().indexOf(searchText) > -1) ||
                    account.gads_account.indexOf(searchText) > -1 ||
                    (!!account.master_id &&
                        account.master_id.filter(id => String(id).indexOf(searchText) > -1).length > 0)) &&
                (!removeUnmanagedFlag || account.status === 'managed') &&
                ((!!filteredNotifications && filteredNotifications.length > 0) ||
                    (!filteredNotifications && messageTypeFilter === 'all')) &&
                (percentageOff === 0 ||
                    percentageOff === '0' ||
                    (account.bar_data && account.bar_data.budgetPercentageOff > percentageOff)) &&
                managementFilter(account.path)
            );
        });        
        setAccountFilter(accountFilter)
        setAllAccountsPage(1)
        setFilteredAccounts(Object.values(filteredAccounts))
        setRemoveUnmanagedFlag(removeUnmanagedFlag)
        setManagerAccountFilter(managerAccountFilter)
        setPercentageOff(percentageOff)
    }

    const handlePage=(allAccountsPage, pageSize) => {
        setAllAccountsPage( allAccountsPage );
    }

    const handlePageSize=(current, size) => {
        setAllAccountsItemsPerPage(size );
    }

    const handleEditMode=(evt, editAccount, editMode, editAccountName) => {
        setEditAccount( editAccount)
        setEditMode( editMode)
        setEditAccountName( editAccountName);
    }

    const handleSortingChanges = (sortingField = 'Name', sortingDirection = 'asc') => {
        setSortingField (sortingField)
        setSortingDirection(    sortingDirection );
    };

    const handleChangeAccountStatus = (account, status=false) => {
        if (!status){
            account.status = account.status === 'managed' ? 'paused' : 'managed';
        }else{
            account.status = status
        }
        updatingAdsManagementAccount(account,{status:account.status});
        allAccounts.map(item=>{
            if (item.gads_account===account.gads_account){
                item.status=account.status
            }
            return item
        })
        setAllAccounts(allAccounts)
        setEditAccount(account)
        handleFilterText(accountFilter, removeUnmanagedFlag, messageTypeFilter, percentageOff)
    }
    
    const handleCopyToClipboard = (text, mes = 'Copied to clipboard') => {
        copy(text);
        message.info(mes);
    };

    const updatingAdsManagementAccount = (account, data)  => {
        return axios
            .put(`${API_BASE_URL}gadsmanagement/managementaccount`, {
                headers: authHeader(),
                data,
                gadsAccount: account.gads_account,
            })
            .then(response => {
                return HelperInternal.processResponse(response, 'Error saving management data.');
            })
            .then(response => {
                const accountEdit={...account,...data}
                setAllAccounts(allAccounts=>{
                    return allAccounts.map(item=>{
                        if (item.gads_account===account.gads_account){
                            return accountEdit
                        }
                        return item
                    })
                });
            })
            .catch(error => {
                message.error(`Data wasn't updated, Sorry: ${error}`);
            });
    }

    const updateAllAccountsFromGad = () => {
        return axios
        .post(`${API_BASE_URL}gadsmanagement/managementaccount/refresh`, {
            headers: authHeader(),
        })
        .then(response => {
            return HelperInternal.processResponse(response, 'Error saving management data.');
        })
        .then(response => {
            const newAccountsQuantity = Object.values(response).length
            if (newAccountsQuantity===0){
                message.info(`No new accounts found`);
                return
            }
            getAllAccounts()
            message.info(`${newAccountsQuantity} new accounts found`);
        })
        .catch(error => {
            message.error(`Data wasn't updated, Sorry: ${error}`);
        });
    }

    const handlePauseAccount = (gadsAccount, data) => {
        return axios
        .put(
            `${API_BASE_URL}gadsmanagement/managementaccount/pause`,
            {
                headers: authHeader(),
                data,
                gadsAccount
            }
        )
        .then(response => {
            return HelperInternal.processResponse(response, 'Error pausing account.');
        })
        .then(campaigns => {
            handleChangeAccountStatus(data, 'paused')
            const allAccountsTemp = allAccounts.map(account => {
                if (account.gads_account!==gadsAccount){return account} 
                return {...account,...{campaigns}}
            });
            setAllAccounts(allAccountsTemp)
        })
        .catch(error => {
            message.error(` ${error} .`);
            console.error(error);
        })
    }

    // TODO: update the exporting logic
    const handleExportButtonClick = () => {
        const currentDate = moment().date()
        const dataExport = filteredAccounts.map(account => {
            let  budgetPercentageOff=0
            let cost3DaysAll=0
            let budgetOdoo=0
            let costYesterdayAll=0 
            let budgetEstimate=0 
            if (account.bar_data){
                budgetPercentageOff = !account.bar_data.budgetPercentageOff?0:account.bar_data.budgetPercentageOff
                cost3DaysAll = (currentDate<4)?0:account.bar_data.cost3DaysAll
                budgetOdoo = account.bar_data.budgetOdoo
                costYesterdayAll = (currentDate===1)?0:account.bar_data.costYesterdayAll
                budgetEstimate = !account.bar_data.budgetEstimate?0:account.bar_data.budgetEstimate
            }
            return [
                `${account.name} ${account.gads_account}`,
                (!account.ATPstatus)?"no":account.ATPstatus,
                account.master_id?account.master_id.join(","):"",
                (!!account.notification && account.notification.length)?account.notification.filter(message=>!!message.pinned).map(message=>message.message.replace("\n"," ")).join(","):"-",
                budgetOdoo.toFixed(2),
                costYesterdayAll ? (costYesterdayAll/GADS_MICRO_MULTIPLIER).toFixed(2) : '0',
                budgetEstimate.toFixed(2),
                (account.bar_data==null || !("cost3DaysAll" in account.bar_data ) || currentDate<4) ? '-' : ((costYesterdayAll-cost3DaysAll)/3/GADS_MICRO_MULTIPLIER).toFixed(2),
                budgetPercentageOff.toFixed(2)+"%",
                account.status
            ].join(';');
        });
        dataExport.unshift([
            'Account Name;Accounts to Pause;Master ID;Pinned messages;Odoo Whoelsale Budget;Google Ads Spend;Estimation of what should have spent;Average Recent Spend;Percentage Off;Active'
        ]);
        const url = window.URL.createObjectURL(
            new Blob([dataExport.join('\r\n')], { type: 'text/plain;charset=utf-8' })
        );
        const link = document.createElement('a');
        link.href = url;
        const fileName = `GADS_Accounts_${moment().format(DATE_FORMAT)}.csv`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const handleExportWeightsButtonClick = () => {
        return axios
            .get(`${API_BASE_URL}gadsmanagement/exportweights`, {
                headers: authHeader()
            })
            .then(response => {
                return HelperInternal.processResponse(response, 'Error saving Account Settings data to DB.');
            })
            .then(dataExport => {
                if (!("data"in dataExport) || !dataExport.data.length) {
                    message.info('Error in data');
                    return;
                }
                const data = dataExport.data
                data.unshift([
                    'Name,Account,Status,Campaign Name,Campaign Value, Campaigns'
                ]);
                const url = window.URL.createObjectURL(
                    new Blob([data.join('\r\n')], { type: 'text/plain;charset=utf-8' })
                );
                const link = document.createElement('a');
                link.href = url;
                const fileName = `GADS_Weights_${moment().format(DATE_FORMAT)}.csv`;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                message.error(`Account Management wasn't updated, Sorry: ${error}`);
            });
    };

    const markMessageReaded=(messageIDs, accountID)=> {
            return axios.put(
                `${API_BASE_URL}gadsmanagement/markmessagereaded`,
                {
                    messageIDs:messageIDs,
                    gadsAccounts:accountID
                },
                {
                    headers: authHeader()
                }
            )
            .then(response => {
                const allAccountsEdit = allAccounts.map(account => {
                    if (account.gads_account === accountID) {
                        account.notification = account.notification.filter(notification => {
                            return messageIDs.indexOf(notification.id)==-1;
                        });
                    }
                    return account;
                })
                setAllAccounts(allAccountsEdit)
            })
            
        }

    const renderMessages=(accountID, notifications)=> {
        if (!notifications) {
            return;
        }
        const notificationsToShow = notifications.filter(notification => {
            return messageTypeFilter===notification.type || messageTypeFilter==="all"   
        });
        return (
            <React.Fragment>                    
                <Row>
                    <Col span={8} offset={16}>
                        <Link style={{fontSize:10, color:"#dddddd", background:"#cf7914",padding:"2px" , textWrap: "nowrap"}} onClick={evt => {
                            markMessageReaded(notifications.map(item=>item.id), accountID);
                        }} type="primary">    
                        Mark All Read
                        </Link>
                    </Col>
                </Row>
                <Table className="notifications-preview">
                    <tbody>
                        {notificationsToShow.sort((a,b)=>{
                        return moment(a.date).diff(moment(b.date),'minutes')<0?1:-1
                        }).slice(0,10).map(item => {
                            return (
                                <tr key={"notification-"+item.id} style={{ borderTop:"1px solid"}}>
                                    <td className="notification-cell">
                                        <Tag style={{fontSize:8,padding:2}} color={item.type.indexOf("error")>-1?"#f50":"#2db7f5"}>
                                            {item.type.split("_")[0]}
                                        </Tag>
                                    </td>
                                    <td  className="notification-cell">
                                        {item.message+" ("+moment(item.date).format("MM/DD")+")"}
                                    </td>
                                    <td  className="notification-cell">
                                        <Link style={{fontSize:10, color:"#dddddd", background:"#cf7914",padding:"2px", textWrap: "nowrap"}}  onClick={evt => {
                                            markMessageReaded([item.id], item.gads_account);
                                        }}>
                                            Mark Read
                                        </Link>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    const renderAccount = (account) => {
        const { name, gads_account} = account;
        let  budgetPercentageOff=0
        let cost3DaysAll=0
        let budgetOdoo=0
        let costYesterdayAll=0 
        let budgetEstimate=0 
        const currentDate = moment().date()
        if (account.bar_data){
            budgetPercentageOff = !account.bar_data.budgetPercentageOff?0:account.bar_data.budgetPercentageOff
            cost3DaysAll = (currentDate<4)?0:account.bar_data.cost3DaysAll
            budgetOdoo = account.bar_data.budgetOdoo
            costYesterdayAll = (currentDate===1)?0:account.bar_data.costYesterdayAll
            budgetEstimate = !account.bar_data.budgetEstimate?0:account.bar_data.budgetEstimate
        }
        if (removeUnmanagedFlag && !(account)) {
            return '';
        }
        let activeElement = '-';
        let ATPdataElement = '-'

        if (account.ATPstatus && account.ATPstatus!=='no')
            { ATPdataElement = (account.ATPstatus==='paused')?<CloseOutlined style={{color:"red"}}/>:<CheckOutlined style={{color:"green"}}/>}

        activeElement = account.status === 'disabled'?<Text>Disabled</Text>:(
            <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={account.status === 'managed'}
                onClick={() => {
                    handleChangeAccountStatus(account);
                }}
            />
        );
        const percentageToday = (moment().day()>22)?5:15
        return (
            <tr key={'account' + gads_account}>
                <td>
                    <Tooltip placement="top" title={account.path}><span className="account-name">{name + ' '}</span></Tooltip>
                    <span style={{color:"#0000C1",cursor:"pointer"}} onClick={()=>handleCopyToClipboard(gads_account,'Account ID copied to clipboard')} className="account-id">({gads_account})</span>
                </td>
                <td style={{textAlign:"center"}}>{loadingATPdata!==2?<SyncOutlined spin />:ATPdataElement}</td>
                <td ><span style={{color:"#0000C1",cursor:"pointer"}} onClick={()=>handleCopyToClipboard(account.master_id,'Master ID copied to clipboard')} className="master-id">{account.master_id}</span></td>
                <td>${budgetOdoo.toFixed(2)}</td>
                <td>${costYesterdayAll ? (costYesterdayAll/GADS_MICRO_MULTIPLIER).toFixed(2) : '0'}</td>
                <td>{budgetEstimate.toFixed(2)}</td>
                <td>{(account.bar_data==null || !("costYesterdayAll" in account.bar_data ))? '-' : (budgetEstimate-costYesterdayAll/GADS_MICRO_MULTIPLIER).toFixed(2)}</td>
                <td>{(account.bar_data==null || !("cost3DaysAll" in account.bar_data ) || currentDate<4) ? '-' : ((costYesterdayAll-cost3DaysAll)/3/GADS_MICRO_MULTIPLIER).toFixed(2)}</td>
                <td>{budgetPercentageOff.toFixed(2)}%</td>
                <td>{account.notification?account.notification.map(message=>{
                    return (!message.pinned)?null:(<div>{message.message}</div>)
                }):"-"}
                </td>
                <td>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Tooltip placement="top" title="Edit spending" color={'green'}>
                                <BarChartOutlined
                                    className="status-icon"
                                    onClick={evt => {
                                        handleEditMode(evt, account, true, name);
                                    }}
                            /></Tooltip>
                        </Col><Col span={6}>  
                        {(account.notification && account.notification.length) ? (
                            <Tooltip placement="top"  color={'#ad4e00'} title={renderMessages(gads_account, account.notification)}>
                                <Badge size="small" count={account.notification.filter(notification => {
                                    return messageTypeFilter===notification.type || messageTypeFilter==="all"   
                                }).length}>
                                    <NotificationTwoTone />
                                </Badge>
                            </Tooltip>
                        ) : (
                            <Tooltip placement="top" title="No messages">
                                <NotificationOutlined />
                            </Tooltip>
                        )}
                        </Col><Col span={6}>  
                        {Math.abs(budgetPercentageOff) > percentageToday ? (
                            <Tooltip placement="top" title={`Percentage is off for more than ${percentageToday}%`}>
                                <FlagTwoTone  twoToneColor="red"/>
                            </Tooltip>
                        ) : (
                            <Tooltip placement="top" title={`Percentage is off for more than ${percentageToday}%`}>
                                <FlagOutlined />
                            </Tooltip>
                        )}
                        </Col>
                    </Row>
                </td>
                <td>{activeElement}<Tooltip title="Pause Account">
                    <Button size="small" shape="circle" icon={<PauseCircleOutlined />} onClick={()=>{handlePauseAccount(gads_account, account)}}></Button></Tooltip>
                </td>
            </tr>
        );
    }

    const renderPaginationBlock = () => {
        const total = filteredAccounts.length;
        return (
            <Row key="pagination-down-block">
                <Pagination
                    total={total}
                    showSizeChanger
                    showQuickJumper
                    pageSize={allAccountsItemsPerPage}
                    current={allAccountsPage}
                    pageSizeOptions={['25', '50', '100']}
                    showTotal={total => `Total ${total} items`}
                    onChange={handlePage}
                    onShowSizeChange={handlePageSize}
                />
            </Row>
        );
    }

    const renderHeaderName = (name, sortingField, sortingDirection) => {
        const headerHelp = {
            'Budget':'Summarized budget from Oddo, Manual and Carryover',
            'Spend':'Spend for current month till yesterday(included)',
            'Estimated':'How much shoud be spent till yesterday to be on track',
            'Average Recent Spend':'Average spend for last 3 days',
            'Active':'Enable/Disable account managing',
            'ATP':'Account To Pause status: X-paused, V-active'
        }
        const headerNoSort = [ 'Messages'];
        const iconStyle = { fontSize: '12px' };
        return (
            <th key={"all-accounts-header-main"+name}  style={{ position : 'relative' }}>
                <Button
                    type={name === sortingField ? 'dashed' : 'link'}
                    onClick={() => {
                        if (headerNoSort.indexOf(name) > -1) {return}
                        let direction = 'asc';
                        if (name === sortingField) {
                            direction = sortingDirection === 'asc' ? 'desc' : 'asc';
                        }
                        handleSortingChanges(name, direction);
                    }}
                >
                    {name}
                    {name === sortingField ? (
                        sortingDirection === 'asc' ? (
                            <ArrowUpOutlined style={iconStyle} />
                        ) : (
                            <ArrowDownOutlined style={iconStyle} />
                        )
                    ) : (
                        ''
                    )}
                </Button>
                {name in headerHelp?
                <Tooltip placement="top" title={headerHelp[name]}>
                    <InfoCircleOutlined style={{ fontSize: '12px',right: '10px',position: 'absolute', top: '13px'}} />
                </Tooltip>:null
                }
            </th>
        );
    }

    const nameColumnsObject = {
        Name: { name: 'name', type: 'string' },
        Budget: { name: 'budgetOdoo', type: 'number_bar' },
        Spend: { name: 'costYesterdayAll', type: 'number_bar' },
        Estimated: { name: 'budgetEstimate', type: 'number_bar' },
        'Over/Under': { name: 'overUnder', type: 'number_bar_formula', 
            calculationFunc:item=>{
                if (!item.bar_data || !"budgetEstimate" in item.bar_data || !"costYesterdayAll" in item.bar_data) return -100000
                return item.bar_data.budgetEstimate-item.bar_data.costYesterdayAll/GADS_MICRO_MULTIPLIER
            }},
        'Average Recent Spend': { name: 'averageRecentSpend', type: 'number_bar_formula' , 
            calculationFunc:item=>{
                if (!item.bar_data || !"cost3DaysAll" in item.bar_data || !"costYesterdayAll" in item.bar_data) return -100000
                return item.bar_data.costYesterdayAll-item.bar_data.cost3DaysAll
            }},
        'Percentage Off': { name: 'budgetPercentageOff', type: 'number_bar' },
        Actions: { name: 'notification', type: 'number_bar_formula' , 
        calculationFunc:item=>{
            if (!item.notification) return -100000
            return item.notification.length
        }},       
        Message: { name: 'name', type: 'string' },
        Active: { name: 'status', type: 'string' },
        'Master ID': { name: 'master_id', type: 'string' },
        'ATP': { name: 'ATPstatus', type: 'string'}
    };
    const compare =
        sortingDirection === 'asc'
            ? (a, b) => {
                    return a > b ? -1 : b > a ? 1 : 0;
                }
            : (a, b) => {
                    return b > a ? -1 : a > b ? 1 : 0;
                };

    const comparingField = nameColumnsObject[sortingField].name;
    const comparingFieldType = nameColumnsObject[sortingField].type;
    const comparingFunction = nameColumnsObject[sortingField].calculationFunc;
    const accountsToRender = filteredAccounts
        .sort((prev, next) => {
            let a, b;
            if (comparingFieldType === 'number') {
                a = typeof prev[comparingField] === 'undefined' ? -1000000 : Number(prev[comparingField]);
                b = typeof next[comparingField] === 'undefined' ? -1000000 : Number(next[comparingField]);
            } else if (comparingFieldType === 'number_bar') 
            {
                a = (!prev.bar_data || typeof prev.bar_data[comparingField] === 'undefined' )? -1000000 : Number(prev.bar_data[comparingField]);
                b = (!next.bar_data || typeof next.bar_data[comparingField] === 'undefined' )? -1000000 : Number(next.bar_data[comparingField]);
            } else if (comparingFieldType === 'number_bar_formula') 
            {
                a = comparingFunction(prev);
                b = comparingFunction(next);
            }else {
                a = prev[comparingField] === 'undefined' ? '' : prev[comparingField];
                b = typeof next[comparingField] === 'undefined' ? '' : next[comparingField];
            }

            return compare(a, b);
        })
        .filter((account, index) => {
            return (
                index >= (allAccountsPage - 1) * allAccountsItemsPerPage &&
                index < allAccountsPage * allAccountsItemsPerPage
            );
        });
            
    return (
        <React.Fragment>        
            <GADSManagementEdit
                forwardedRef={gadsManagementEdit}
                handleEditMode={handleEditMode}
                allAccounts={allAccounts}
                editMode={editMode}
                editAccount={editAccount}
                editAccountName={editAccountName}
                setAllAccounts={setAllAccounts}
                startDate={startDate}
                endDate={endDate}
                getAllAccounts={getAllAccounts}
                markMessageReaded={markMessageReaded}
                handleChangeAccountStatus={handleChangeAccountStatus}
                allUsers={allUsers}
            />
            <Grid fluid key="block-container">
                <Row key="dates-block">
                    <Col span={21} style={{ marginTop: 5 }}>
                        <Row>
                            <Card title="Filters" style={{ width: '100%' }}>
                                <Row>
                                    <Col className="card-div-inner-1" span={5}>
                                        <Text>Filter Text:</Text>
                                        <Input
                                            onChange={evt => {
                                                handleFilterText(evt.target.value, removeUnmanagedFlag, messageTypeFilter, percentageOff, managerAccountFilter);
                                            }}
                                            placeholder="Enter the text for account filtering"
                                            defaultValue={accountFilter}
                                        />
                                    </Col>
                                    <Col className="card-div-inner-1" span={3}>
                                        <Text>Percentage Off:</Text>
                                        <Input
                                            onChange={evt => {
                                                handleFilterText(accountFilter, removeUnmanagedFlag, messageTypeFilter, evt.target.value, managerAccountFilter);
                                            }}
                                            placeholder="Enter the Percentage Off for account filtering"
                                            defaultValue={percentageOff}
                                        />
                                    </Col>
                                    <Col span={4}  style={{textAlign: 'center'}}>
                                        <Text>Has Messages Type:</Text>
                                            <br />
                                        <Select
                                            defaultValue={messageTypeFilter}
                                            style={{ width: 120 }}
                                            onChange={(evt) => {
                                                handleFilterText(accountFilter, removeUnmanagedFlag, evt, percentageOff, managerAccountFilter);
                                            }}                                           
                                            options={[
                                                { value: 'all', label: 'All' },
                                                { value: 'any', label: 'Any' },
                                                { value: 'user', label: 'User' },
                                                { value: 'odoo_info', label: 'Odoo Info'},
                                                { value: 'odoo_error', label: 'Odoo Error'},
                                                { value: 'gads_info', label: 'GADS Info'},
                                                { value: 'gads_error', label: 'GADS Error'},
                                            ]}
                                        />
                                    </Col>
                                    <Col span={4} style={{textAlign: 'center'}}>
                                        <Text>Managed Only</Text>
                                        <br />
                                        <Checkbox
                                            checked={removeUnmanagedFlag}
                                            onChange={() => {
                                                handleFilterText(accountFilter, !removeUnmanagedFlag, messageTypeFilter, percentageOff, managerAccountFilter);
                                            }}
                                        ></Checkbox>
                                    </Col>
                                    <Col span={8}  style={{textAlign: 'center'}}>
                                        <Text>Managed By:</Text>
                                            <br />
                                        <Select
                                            defaultValue={managerAccountFilter}
                                            style={{ width: '100%' }}
                                            onChange={(evt) => {
                                                handleFilterText(accountFilter, removeUnmanagedFlag, messageTypeFilter, percentageOff, evt);
                                            }}                                           
                                            options={[
                                                { value: 'all_managements', label: 'All accounts' },
                                                { value: 'all_except', label: 'All accounts except 3 specified below' },
                                                { value: '3186414929', label: 'Canceled Accounts - Auto/Local/Old Res/SL 318-641-4929'},
                                                { value: '7406500815', label: 'Z_CL_Canceled Res/SL Accounts 740-650-0815'},
                                                { value: '7116929211', label: 'Auto/Local Accounts 711-692-9211'},
                                            ]}
                                        />
                                    </Col>
                                </Row>


                            </Card>
                        </Row>
                    </Col>
                    <Col span={3} className="update-export-group">
                        <Tooltip title="Update Account List from GAD">
                            <Button className="ant-col-24" onClick={updateAllAccountsFromGad}>
                                UPDATE
                            </Button>
                        </Tooltip>
                        <Tooltip title="Exporting all accounts to CSV">
                            <Button className="ant-col-24" onClick={handleExportButtonClick}>
                                EXPORT
                            </Button>
                        </Tooltip>
                        <Tooltip title="Exporting all accounts to CSV">
                            <Button className="ant-col-24" onClick={handleExportWeightsButtonClick}>
                                EXPORT WEIGHTS
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
                <Row className="no-data-row">
                    <Col span={20}>{renderPaginationBlock()}</Col>
                    {/* <Col className="status-loading" span={4}>
                        {true ? (
                            <div className="blinking-message">
                                <InfoCircleOutlined />
                                BAR Data Loading...
                            </div>
                        ) : (
                            ''
                        )}
                    </Col> */}
                </Row>
                <Col span={24}>
                    <Spin size="large" spinning={loadingAccounts}>
                        {filteredAccounts.length ? (
                            <div>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr key='heder-accounts-filtered'>
                                            {[
                                                'Name',
                                                'ATP',
                                                'Master ID',
                                                'Budget',
                                                'Spend',
                                                'Estimated',
                                                'Over/Under',
                                                'Average Recent Spend',
                                                'Percentage Off',
                                                'Messages',
                                                'Actions',
                                                'Active'
                                            ].map(headerName => {
                                                return renderHeaderName(
                                                    headerName,
                                                    sortingField,
                                                    sortingDirection
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accountsToRender.map(account => {
                                            return renderAccount(account);
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        ) : (
                            <Row>
                                <Col span={4}>
                                    <div className="no-data">
                                        <span>NO DATA</span>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Spin>
                </Col>
            </Grid>
        </React.Fragment>
    );
    
}


